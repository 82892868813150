
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SalesFunnel } from '../entities/sales-funnel.entity';

@Component
export default class SalesFunnelCardComponent extends Vue {
  @Prop({
    type: Object,
    default: () => new SalesFunnel(),
  })
  private readonly order!: SalesFunnel;

  private goToProposal() {
    const routeData = this.$router.resolve({
      name: 'client-edit',
      params: {
        id: this.order.id,
      },
    });
    window.open(routeData.href, '_blank');
  }
}
