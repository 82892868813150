import moment from '@/services/moment/moment.service';

export class SalesFunnelFilter {
  public enterprises: string[];

  public selling_points: string[];

  public users: string[];

  public created_at_start: string = '';

  public created_at_end: string = '';

  public limit: any = undefined;

  constructor(data: any = {}) {
    this.enterprises = data.enterprises || [];
    this.selling_points = data.selling_points || [];
    this.users = data.users || [];
    this.created_at_start = data.created_at_start;
    this.created_at_end = data.created_at_end;
    this.limit = data.limit || 10;
  }

  public format() {
    return {
      ...this,
      created_at_start: this.created_at_start
        ? moment(this.created_at_start).format('YYYY-MM-DD')
        : undefined,
      created_at_end: this.created_at_end
        ? moment(this.created_at_end).format('YYYY-MM-DD')
        : undefined,
    };
  }
}
