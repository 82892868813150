export class MouseHandlerScrollHorizontal {
  private container: HTMLElement;

  private pos: any;

  constructor() {
    this.container = document.getElementById('container') as HTMLElement;
    this.pos = {
      top: 0,
      left: 0,
      x: 0,
      y: 0,
    };
  }

  public startScrollHandler() {
    const mouseMoveHandler = (e: any) => {
      const dx = e.clientX - this.pos.x;
      const dy = e.clientY - this.pos.y;

      this.container.scrollTop = this.pos.top - dy;
      this.container.scrollLeft = this.pos.left - dx;
    };

    const mouseUpHandler = () => {
      this.container.style.cursor = '';
      this.container.style.removeProperty('user-select');

      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    const mouseDownHandler = (e: any) => {
      this.container.style.cursor = 'grabbing';
      this.container.style.userSelect = 'none';

      this.pos = {
        left: this.container.scrollLeft,
        top: this.container.scrollTop,
        x: e.clientX,
        y: e.clientY,
      };

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    this.container.addEventListener('mousedown', mouseDownHandler);
  }
}
