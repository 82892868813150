
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Container, Draggable } from 'vue-smooth-dnd';
import { Status } from '@/modules/Administrative/modules/Status/entities/status.entity';
import { SalesFunnelFilter } from '../entities/sales-funnel-filter.entity';
import SalesFunnelBoardColumnComponent from './sales-funnel-board-column.component.vue';

@Component({
  components: { Container, Draggable, SalesFunnelBoardColumnComponent },
})
export default class SalesFunnelBoardComponentVue extends Vue {
  @Prop({ type: Array, default: () => [] })
  private statusList: any;

  public $refs: any;

  @Prop({
    type: String,
    default: () => '',
  })
  public readonly companyId!: string;

  @Prop({
    type: Object,
    default: () => ({}),
  })
  public readonly filter!: SalesFunnelFilter;

  public title(status: Status) {
    return `${status.description} ${this.statusList.length}`;
  }

  public statusColor(status: Status) {
    return status.color;
  }

  public getSalesFunnelItems() {
    // gambiarra master do domingo a noite
    this.$refs.columnItemComponent.forEach((it: any) => {
      it.getSalesFunnelItems();
    });
  }

  public refreshPaginate() {
    this.$refs.columnItemComponent.forEach((it: any) => {
      it.refreshPaginate();
    });
  }
}
