
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Container, Draggable } from 'vue-smooth-dnd';
import { Substatus } from '@/modules/Administrative/modules/Substatus/entities/substatus.entity';
import substatusService from '@/modules/Administrative/modules/Substatus/services/substatus.service';
import { Status } from '@/modules/Administrative/modules/Status/entities/status.entity';
import { StatusEnum } from '@/modules/Clients/components/form/enums/status.enum';
import { SalesFunnel } from '../entities/sales-funnel.entity';
import salesFunnelService from '../service/sales-funnel.service';
import { SalesFunnelFilter } from '../entities/sales-funnel-filter.entity';
import SalesFunnelCard from './sales-funnel-card.component.vue';
import SalesFunelSubstatusComponent from './sales-funel-substatus.component.vue';

@Component({
  components: {
    Container,
    Draggable,
    SalesFunnelCard,
    SalesFunelSubstatusComponent,
  },
})
export default class SalesFunnelBoardColumnComponent extends Vue {
  public dropPlaceholderOptions = {
    className: 'drop-preview',
    animationDuration: '150',
    showOnTop: true,
  };

  public $refs: any;

  @Prop({
    type: String,
    default: () => '',
  })
  public readonly companyId!: string;

  @Prop({
    type: Object,
    default: () => new Status(),
  })
  public readonly status!: Status;

  @Prop({
    type: Object,
    default: () => ({}),
  })
  public readonly filter!: SalesFunnelFilter;

  public salesFunnelItem: SalesFunnel[] = [];

  public substatus: string[] = [];

  public substatusSelected: Substatus = new Substatus();

  public dropdown_font = ['Arial', 'Calibri', 'Courier', 'Verdana'];

  public loading: boolean = false;

  public page: number = 1;

  public lastpage: boolean = false;

  public totalItems: number = 0;

  public maxPage: number = 1;

  public refreshPaginate() {
    this.lastpage = false;
    this.totalItems = 0;
    this.maxPage = 1;
    this.page = 1;
  }

  async onCardDrop(status: Status, dropResult: any) {
    const notModified = dropResult.removedIndex === null && dropResult.addedIndex === null;
    if (notModified) return;
    const hasItem = dropResult.payload;
    if (!hasItem) return;
    const isSameItem = dropResult.removedIndex !== null && dropResult.addedIndex !== null;

    if (isSameItem) return;

    if (dropResult.addedIndex !== null) {
      if (status.type !== StatusEnum.INITIAL_CONTACT) {
        this.$snackbar.open({
          text: 'Não é permitido mover para esse status',
          color: 'danger',
        });
        return;
      }
      this.loading = true;
      await salesFunnelService
        .changeStatusByStatusId(status.id, dropResult.payload.id)
        .catch((err: any) => {
          this.$snackbar.open({
            text: err.response.data.message,
            color: 'danger',
            buttonColor: 'white',
          });
          this.loading = false;
        });
      await this.getSalesFunnelItems();
      this.loading = false;
      return;
    }
    if (dropResult.removedIndex !== null) {
      this.loading = true;
      setTimeout(async () => {
        await this.getSalesFunnelItems();
        this.loading = false;
      }, 3000);
    }
  }

  public sortByName(salesFunnelItems: SalesFunnel[] = []) {
    return [...salesFunnelItems].sort((a: SalesFunnel, b: SalesFunnel) => {
      if (a.proponent_name < b.proponent_name) {
        return -1;
      }
      if (a.proponent_name > b.proponent_name) {
        return 1;
      }
      return 0;
    });
  }

  getSalesFunnelContent(salesFunnelIndex: any) {
    return this.salesFunnelItem[salesFunnelIndex];
  }

  public async getSalesFunnelItems(paginateAction: boolean = false) {
    if (paginateAction && this.totalItems && this.totalItems === this.salesFunnelItem.length) {
      return;
    }
    const response = await salesFunnelService.getSalesFunnelItems({
      ...this.filter.format(),
      companyId: this.companyId,
      status_id: this.status.id,
      sub_status_id: this.substatusSelected.id,
    });
    const salesFunnelItem = response.data;
    this.lastpage = response.meta.last_page === 1;
    this.totalItems = response.meta.total;
    this.salesFunnelItem = [];
    if (paginateAction) {
      this.salesFunnelItem = this.sortByName([
        ...this.salesFunnelItem,
        ...salesFunnelItem.map((item: any) => new SalesFunnel(item)),
      ]);
      return;
    }
    this.salesFunnelItem = this.sortByName(
      salesFunnelItem.map((item: any) => new SalesFunnel(item)),
    );
  }

  public async getSubstatusByStatus() {
    this.substatus = await substatusService.getSubstatusByStatusItem(
      this.status.id,
      this.companyId,
    );
  }

  public async setSubstatusSelected(substatusItem: Substatus) {
    this.loading = true;
    this.substatusSelected = substatusItem;
    await this.getSalesFunnelItems();
    this.loading = false;
  }

  public async clearSubstatus() {
    this.loading = true;
    this.substatusSelected = new Substatus();
    await this.getSalesFunnelItems();
    this.loading = false;
  }

  public handleInfinityScroll(element: any) {
    if (element) {
      element.addEventListener('scroll', (event: any) => {
        const bottom: any = event.target.scrollHeight - event.target.scrollTop;
        if (parseInt(bottom, 10) === parseInt(event.target.clientHeight, 10)) {
          this.getSalesFunnelItems(true);
          this.page += 1;
        }
      });
    }
  }

  public async created() {
    await this.getSalesFunnelItems();
    await this.getSubstatusByStatus();
  }
}
