import { AxiosResponse } from 'axios';
import http from '@/services/http.service';

class SalesFunnelService {
  public getSalesFunnelItems(funnelParams: any) {
    return http
      .get('/sales-funnel/', {
        params: {
          ...funnelParams,
          company_id: funnelParams.companyId,
          paginate: 1,
          limit: funnelParams.limit,
        },
      })
      .then(({ data }: AxiosResponse) => data);
  }

  public changeStatusByStatusId(statusId: string, orderId: string) {
    return http
      .post('/sales-funnel/change-status', {
        order_id: orderId,
        status_id: statusId,
      })
      .then(({ data }: AxiosResponse) => data);
  }
}

export default new SalesFunnelService();
