export class SalesFunnel {
  public unit: string = '';

  public enterprise: string = '';

  public id: string = '';

  public proponent_cellphone: number = 0;

  public proponent_name: string = '';

  public created_by_name: string = '';

  public status: string = '';

  public sub_statuses: string[] = [];

  constructor(data: any = {}) {
    this.id = data.id;
    this.enterprise = data.enterprise || '';
    this.unit = data.unit || '';
    this.proponent_cellphone = data.proponent_cellphone || '';
    this.created_by_name = data.created_by_name || '';
    this.proponent_name = data.proponent_name || '';
    this.status = data.status || '';
    this.sub_statuses = data.sub_statuses || [];
  }

  public get phoneFormatted() {
    return this.proponent_cellphone
      ? String(this.proponent_cellphone).replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3')
      : '';
  }
}
