
import { Component, Vue } from 'vue-property-decorator';
import { debounce } from 'lodash';
import moment from '@/services/moment/moment.service';
import DatePicker from '@/components/date-picker/date-picker.component.vue';
import { SellingPoint } from '@/modules/Administrative/modules/SellingPoints/entities/selling-point.entity';
import sellingPointsService from '@/modules/Administrative/modules/SellingPoints/services/selling-points.service';
import { User } from '@/modules/Administrative/modules/Users/entities/user.entity';
import { Enterprise } from '@/modules/Administrative/modules/Enterprises/entities/enterprise.entity';
import userService from '@/modules/Administrative/modules/Users/services/user.service';
import statusService from '@/modules/Administrative/modules/Status/services/status.service';
import { Status } from '@/modules/Administrative/modules/Status/entities/status.entity';
import SalesFunnelBoard from './sales-funnel-board.component.vue';
import enterpriseService from '../../Administrative/modules/Enterprises/services/enterprise.service';
import { Company } from '../../Administrative/modules/Companies/entities/company.entity';
import salesFunnelService from '../service/sales-funnel.service';
import SalesFunnel from '../SalesFunnel.vue';
import clientService from '../../Clients/services/client.service';
import { MouseHandlerScrollHorizontal } from '../services/mouse-handler-scroll-horizontal.service';
import { SalesFunnelFilter } from '../entities/sales-funnel-filter.entity';

@Component({
  components: {
    SalesFunnelBoard,
    DatePicker,
  },
})
export default class SalesFunnelWrapper extends Vue {
  public $refs: any;

  private companies: Company[] = [];

  private periodType: string = '';

  private companyId: string = this.$store.getters.user.company_id;

  private salesFunnelItems: SalesFunnel[] = [];

  private sellingPoints: SellingPoint[] = [];

  private users: User[] = [];

  private enterprises: Enterprise[] = [];

  private itemsPerColumnData = [
    { value: 10, description: 10 },
    { value: 20, description: 20 },
    { value: 30, description: 30 },
    { value: undefined, description: 'Todos' },
  ];

  private itemsPerColumn = 10;

  private periodTypes = {
    TODAY: 'TODAY',
    LASTDAYS: 'LASTDAYS',
    CUSTOM: 'CUSTOM',
  };

  private filter: SalesFunnelFilter = new SalesFunnelFilter();

  private statusList: Status[] = [];

  private async getCompanies() {
    const companies = await enterpriseService.getCompanies();
    this.companies = companies.map((it: Company) => new Company(it));
    this.companyId = this.companies.length ? this.companies[0].id : '';
  }

  private refreshPaginate() {
    this.$refs.columnItemComponent.refreshPaginate();
  }

  private getSalesFunnelItems() {
    this.$refs.columnItemComponent.getSalesFunnelItems();
  }

  private refreshSalesFunnel = debounce(() => {
    this.getSalesFunnelItems();
  }, 600);

  private async getStatus() {
    this.statusList = await statusService.getStatusByCompanyId(this.companyId);
    this.$nextTick(() => {
      this.startScrollHandler();
    });
  }

  private startScrollHandler() {
    new MouseHandlerScrollHorizontal().startScrollHandler();
  }

  private async getSellingPoints() {
    if (!this.companyId) return;
    const sellingPoints = await sellingPointsService.getSellingPointByCompanyId(this.companyId);
    this.sellingPoints = sellingPoints.map((it: SellingPoint) => new SellingPoint(it));
  }

  private async getUsers() {
    const users = await userService.getAllUsers({});
    this.users = users.map((user: User) => new User(user));
  }

  private async changeCompanyId(company_id: string) {
    this.companyId = company_id;
    await this.getStatus();
    this.getSellingPoints();
    this.getEnterprises();
    this.getUsers();
    this.refreshPaginate();
    this.clearPeriods();
    this.periodType = '';
    this.$nextTick(async () => {
      this.getSalesFunnelItems();
    });
  }

  private setCreatedEnd(createdEnd: string) {
    this.filter.created_at_end = createdEnd;
    this.refreshPaginate();
    this.refreshSalesFunnel();
  }

  setLimitToFilter(limit: number) {
    this.itemsPerColumn = limit;
    this.filter.limit = limit;
    this.getSalesFunnelItems();
  }

  private setCreatedStart(createdStart: string) {
    this.filter.created_at_start = createdStart;
    this.refreshPaginate();
    this.refreshSalesFunnel();
  }

  private allowedDaysStart(value: string) {
    if (this.filter.created_at_end) {
      return moment(value, 'YYYY-MM-DD').isSameOrBefore(
        moment(this.filter.created_at_end).format('YYYY-MM-DD'),
      );
    }
    return moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'));
  }

  private allowedDaysEnd(value: string) {
    if (this.filter.created_at_start) {
      return (
        moment(value, 'YYYY-MM-DD').isSameOrAfter(
          moment(this.filter.created_at_start).format('YYYY-MM-DD'),
        ) && moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'))
      );
    }
    return moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'));
  }

  private setPeriodToday() {
    this.filter.created_at_start = moment().format('YYYY-MM-DD');
    this.filter.created_at_end = moment().format('YYYY-MM-DD');
  }

  private setPeriodLastDays() {
    this.filter.created_at_start = moment().subtract(7, 'day').format('YYYY-MM-DD');
    this.filter.created_at_end = moment().format('YYYY-MM-DD');
  }

  private clearPeriods() {
    this.filter.created_at_start = '';
    this.filter.created_at_end = '';
  }

  private setPeriodType(period: string) {
    this.periodType = period;

    const periodsFn = {
      [this.periodTypes.TODAY]: this.setPeriodToday,
      [this.periodTypes.LASTDAYS]: this.setPeriodLastDays,
      [this.periodTypes.CUSTOM]: this.clearPeriods,
    };

    periodsFn[this.periodType]();
    this.refreshPaginate();
    this.$nextTick(async () => {
      this.getSalesFunnelItems();
    });
  }

  private async getEnterprises() {
    if (!this.companyId) return;
    const enterprises = await enterpriseService.getEnterpriseByCompanyId(this.companyId);
    this.enterprises = enterprises.map((enterprise: Enterprise) => new Enterprise(enterprise));
  }

  private async created() {
    if (this.$permissionAccess.group.isSuperAdmin) {
      await this.getCompanies();
    }
    this.getStatus();
    this.getSellingPoints();
    this.getEnterprises();
    this.getUsers();
  }
}
