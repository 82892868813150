
import { Component, Vue, Prop } from 'vue-property-decorator';
import substatusService from '@/modules/Administrative/modules/Substatus/services/substatus.service';
import { Substatus } from '@/modules/Administrative/modules/Substatus/entities/substatus.entity';

@Component
export default class SalesFunelSubstatusComponent extends Vue {
  @Prop({ type: String, default: () => '' })
  private readonly statusId!: string;

  @Prop({ type: String, default: () => '' })
  private readonly companyId!: string;

  private substatus = [];

  private async getSubstatusByStatus() {
    this.substatus = await substatusService.getSubstatusByStatusItem(this.statusId, this.companyId);
  }

  private filterSubstatus(substatus: Substatus) {
    this.$emit('set-substatus-filter', substatus);
  }

  private created() {
    this.getSubstatusByStatus();
  }
}
